import {mapActions, mapGetters, mapMutations} from "vuex";


//sections


export default {
    name: "comment",
    props: {
        item: {
            type: Object,
            default: {}
        },
        question: {
            type: Boolean,
            default: true
        }
    },
    components: {},
    data() {
        return {
            rating: 3
        }
    },
    created() {
    },
    computed: {
        ...mapGetters({
            isAuthenticated: 'auth/isAuthenticated'
        })
    },

    methods: {
        ...mapMutations({
            closeQuestionPopup: 'card/SHOW_QUESTION_POPUP',
            setParentQuestionId: 'card/SET_QUESTION_ID',
            closeCommentPopup: 'card/SHOW_COMMENT_POPUP',
            closeRegisterPopup: 'auth/SHOW_REGISTER_POPUP',
        }),
        ...mapActions({}),
        submit(item) {
            if (!this.isAuthenticated) {
                this.closeRegisterPopup(true)
            } else {
                this.closeCommentPopup(true)
                this.setParentQuestionId(item)
            }
        }
    }
}
